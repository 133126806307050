<template>
  <div id="dashboard-tab">
    <div class="ds-container">
      <div class="ds-header">
        <div class="ds-title">
          Dashboard
          <span id="help-icon" class="icon-box"><HelpCircle class="icon stroke" /></span>
          <b-tooltip target="help-icon" placement="bottom">
            Confira aqui os dados e resumos mais importantes do financeiro, utilize os filtros para personalizar sua visualização.
          </b-tooltip>
        </div>

        <div class="d-flex flex-row align-items-center" style="gap: 10px;">
          <multiselect
            track-by="id"
            label="label"
            v-model="bankAccountIdSelected"
            placeholder="Selecionar conta"
            :options="bankAccount"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            :disabled="false"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="singleLabel" slot-scope="{ option }" >
              <span class="account-options">{{ option.label }}</span>
            </template>

            <template slot="noOptions">
              Nenhuma opção
            </template>

            <template slot="noResult">
              Nenhum resultado
            </template>
          </multiselect>

          <!-- <b-button class="wh-button no-print ml-2" variant="outline-primary" outline>
            Imprimir
          </b-button> -->
        </div>
      </div>

      <div class="content">
        <table class="grid-table">
          <tr>
            <td class="card-component small-card">
              <SimpleCard
                isMoney
                cursor="pointer"
                title="Saldo na conta"
                :value="this.bankAccountIdSelected.account_balance"
                color="var(--dark-blue)"
                :onClick="() => onChangeTab(`/financeiro/fluxo_de_caixa?bank=${bankAccountIdSelected.label}&bankId=${bankAccountIdSelected.id}`)"
              />
            </td>
            <td class="small-card" rowspan="2">
              <MonthlyCard
                type="ENTRANCE"
                :api="this.api.getInputOutputMonthly"
                systemModule="Financial"
                :bankAccountId="bankAccountIdSelected.id ?? null"
              />
            </td>

            <td class="card-component medium-card" rowspan="4" >
              <DonutChartComponent
                title="Convênios"
                v-if="mostPopularHealthPlansLabels.length > 0"
                :labels="mostPopularHealthPlansLabels"
                :colors="['var(--light-blue)', 'var(--neutral-500)', 'var(--orange)', 'var(--yellow)']"
              />

              <div class="card noDataCard" v-else>
              Nenhum dado a ser exibido
              </div>
            </td>

            <td class="card-component medium-card" rowspan="4" >
              <DonutChartComponent
                title="Formas de recebimento populares"
                v-if="mostPopularPaymentMethodLabels.length  > 0"
                :labels="mostPopularPaymentMethodLabels"
                :colors="['var(--neutral-400)', 'var(--yellow)', 'var(--light-orange-500)', 'var(--light-blue)']"
              />

              <div class="card noDataCard" v-else>
                Nenhum dado a ser exibido
              </div>
            </td>
          </tr>

          <tr>
            <td class="card-componentsmall-card">
              <SimpleCard
                isMoney
                cursor="pointer"
                title="Valor a receber hoje"
                :value="totalBillToReceiveToday"
                color="#069763"
                :onClick="() => onChangeTab('/financeiro/contas_a_receber?p=h')"
              />
            </td>
          </tr>

          <tr>
            <td class="card-component small-card" rowspan="1">
              <SimpleCard
                isMoney
                cursor="pointer"
                title="Valor a pagar hoje"
                :value="totalBillToPayToday"
                color="var(--states-error)"
                :onClick="() => onChangeTab('/financeiro/contas_a_pagar?p=h')"
              />
            </td>

            <td class="small-card" rowspan="2">
              <MonthlyCard
                type="OUTPUT"
                :api="this.api.getInputOutputMonthly"
                systemModule="Financial"
                :bankAccountId="bankAccountIdSelected?.id"
              />
            </td>
          </tr>

          <tr>
            <td class="card-component small-card" rowspan="1">
              <SimpleCard
                cursor="pointer"
                title="Contas a vencer hoje"
                :value="countBillToPayToday"
                color="var(--light-orange-500)"
                :onClick="() => onChangeTab('/financeiro/transacoes?p=sh')"
              />
            </td>
          </tr>
        </table>

        <div class="dashboard-bottom">
          <div class="dashboard-bottom-container-extern">
            <div class="dashboard-bottom-container-header">
              <p class="dashboard-bottom-container-title">Movimentações por período</p>

              <div @click="chevronRotate">

                <date-picker
                  range
                  v-model="period"
                  format="DD [de] MMMM"
                  :clearable="true"
                  :lang="langDatePicker"
                  v-on:blur="resetChevronState"
                  prefix-class="dx"
                  class="dx-datepicker"
                  placeholder="Selecione um intervalo"
                >

                  <template slot="icon-calendar">
                    <div id="chevron">
                      <ChevronDown class="datepicker-chevrondown-icon" />
                    </div>
                  </template>
                </date-picker>
              </div>
            </div>

            <div class="dashboard-bottom-container-intern">

              <MovementOfWeekCard
                :getBillsBalance="getBillsBalance"
                :billsBalance="billsBalance"
              />

              <div class="week-total-values">
                <SimpleCard
                  isMoney
                  title="Valor total de entradas do período"
                  :value="billsBalanceWeek ? billsBalanceWeek.entrances : 0"
                  color="#069763"
                />

                <SimpleCard
                  isMoney
                  title="Valor total de saídas do período"
                  :value="billsBalanceWeek ? billsBalanceWeek.outputs : 0"
                  color="#D82828"
                />

                <SimpleCard
                  isMoney
                  title="Saldo total do período"
                  :value="billsBalanceWeek ? billsBalanceWeek.difference : 0"
                  color="var(--dark-blue)"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="cash-flow-wrapper">
          <CashFlowChart height="400px" :bankAccounts="bankAccount" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentClinic } from '../../../utils/localStorageManager';

export default {
  components: {
    CashFlowChart: () => import('@/modules/financial/components/CashFlowChart'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    DonutChartComponent: () => import('@/components/Financial/DonutChartComponent'),
    HelpCircle: () => import('@/assets/icons/help-circle.svg'),
    MonthlyCard: () => import('@/components/Dashboard/MonthlyCard'),
    MovementOfWeekCard: () => import('@/components/Financial/MovementOfWeekCard'),
    SimpleCard: () => import('@/components/Dashboard/SimpleCard'),
  },
  async mounted() {
    await this.bankAccounts();
    this.totalBillToReceiveToday = await this.getTotalBillsToReceiveToday();
    this.totalBillToPayToday = await this.getTotalBillsToPayToday();
    this.countBillToPayToday = await this.getBillsToPayToday();
    await this.getMostPopularHealthPlans();
    await this.getMostPopularPaymentMethods();
    this.bankAccountIdSelected.account_balance = this.getTotalAccountBalance();

    const end = new Date();
    const start = new Date(new Date().setHours(0, 0, 0, 0) - 518400000);
    this.period = [start, end];
  },
  data() {


    return {
      chevronState: false,
      period: [null, null],
      billsBalanceWeek: null,
      billsBalance: null,
      billsToPay: [],
      paymentMethods: [],
      plans: [],
      clinic: getCurrentClinic(),

      mostPopularHealthPlansLabels: [],
      mostPopularPaymentMethodLabels: [],

      bankAccountIdSelected: this.allBankAccountOption(),
      bankAccount: [],
      countBillToPayToday: 0,
      totalBillToReceiveToday: 0,
      totalBillToPayToday: 0,
      selected: null,
      options: [
        { value: null, text: 'Please select an option' },
        { value: 'a', text: 'This is First option' },
        { value: 'b', text: 'Selected Option' },
        { value: { C: '3PO' }, text: 'This is an option with object value' },
        { value: 'd', text: 'This one is disabled', disabled: true }
      ],
      langDatePicker: {
        formatLocale: {
	        weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        },
      }
    }
  },

  methods: {
    allBankAccountOption() {
      return {
        label: 'Todas as contas',
        id: '',
        account_balance: 0,
      }
    },
    chevronRotate() {
      if(this.chevronState === false) {
        const chevron = document.getElementById('chevron')
        chevron.classList.toggle('chevron-active')
        this.chevronState = true
      }
    },
    resetChevronState() {
      if(this.chevronState === true) {
        const chevron = document.getElementById('chevron')
        chevron.classList.toggle('chevron-active')
        this.chevronState = false
      }
    },
    async getBillsBalance(startDay, endDay) {
      this.billsBalance = null
      const res = await this.api.getBillsBalance(this.clinic.id, startDay, endDay, this.bankAccountIdSelected?.id)
      this.billsBalance = res.data
    },
    async getBillsBalanceWeek(startDay, endDay) {
      this.billsBalanceWeek = null
      const res = await this.api.getBillsBalanceWeek(this.clinic.id, startDay, endDay, this.bankAccountIdSelected?.id);
      this.billsBalanceWeek = res.data
    },
    async bankAccounts() {
      const response = await this.api.getBankAccountBillToReceive(this.clinic.id);
      const bankAccounts = [this.allBankAccountOption()];
      this.bankAccount = bankAccounts.concat(response.data.map(el => ({ ...el, label: `${el.name}` })));
    },
    async getMostPopularHealthPlans() {
      const res = await this.api.getMostPopularHealthPlans(this.clinic.id);
      const keys = Object.keys(res.data);
      this.mostPopularHealthPlansLabels = keys.map(key => [key, res.data[key]]);
    },
    async getMostPopularPaymentMethods() {
      const res = await this.api.getMostPopularPaymentMethods(this.clinic.id, this.bankAccountIdSelected.id);
      const keys = Object.keys(res.data);
      this.mostPopularPaymentMethodLabels = keys.map(key => [key, res.data[key]]);
    },
    async getBillsToPayToday() {
      try {
        const response = await this.api.getBillsToPayToday(this.clinic.id, this.bankAccountIdSelected.id)
        return response.data
      } catch (error) {
        throw new Error(400)
      }
    },
    async getTotalBillsToReceiveToday() {
      try {
        const response = await this.api.getTotalBillsToReceiveToday(this.clinic.id, this.bankAccountIdSelected.id)
        return response.data
      } catch (error) {
        throw new Error(400)
      }
    },
    async getTotalBillsToPayToday() {
      try {
        const response = await this.api.getTotalBillsToPayToday(this.clinic.id, this.bankAccountIdSelected.id)
        return response.data
      } catch (error) {
        throw new Error(400)
      }
    },
    getTotalAccountBalance() {
      let sumTotal = 0;
      this.bankAccount.map(bankAccount => {
        if (bankAccount.label !== 'Todas as contas') {
          sumTotal += bankAccount.account_balance;
        }
      });
      return sumTotal;
    },
    onChangeTab(push) {
      if (push) {
        this.$router.push(push, true);
      }
    },
  },

  watch: {
    period: async function() {
      await this.getBillsBalance(this.period[0], this.period[1]);
      await this.getBillsBalanceWeek(this.period[0], this.period[1]);
    },
    bankAccountIdSelected: async function() {
      if (this.bankAccountIdSelected.label === 'Todas as contas') {
        this.bankAccountIdSelected.account_balance = this.getTotalAccountBalance();
      }

      const isLoading = this.$loading.show();
      try {
        this.countBillToPayToday = await this.getBillsToPayToday();
        this.totalBillToReceiveToday = await this.getTotalBillsToReceiveToday();
        this.totalBillToPayToday = await this.getTotalBillsToPayToday();
        this.getMostPopularPaymentMethods()
        await this.getBillsBalance(this.period[0], this.period[1]);
        await this.getBillsBalanceWeek(this.period[0], this.period[1]);
      } catch(err) {
        this.$toast.error(err.message)
      } finally {
        isLoading.hide();
      }
    },
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/dx-datepicker.scss';

#dashboard-tab {
  .ds-container {
    padding: 30px 10px;

      .ds-header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0 30px 0;

        .wh-button {
          color: var(--blue-500);
          height: 40px !important;
          font-weight: 700 !important;
        }

        .multiselect {
          width: 198px;
          border: 2px solid var(--blue-500);
          border-radius: 8px;

          .multiselect__tags {
            min-height: 35px !important;

            .multiselect__single {
              font-weight: 700 !important;
              font-size: 16px;
              line-height: 24px;
              color: var(--blue-500);
            }
          }
        }

        .chevron {
          width: 24px;
          height: 24px;
          stroke: var(--blue-500);
          cursor: pointer;

          svg {
            stroke: var(--blue-500);
          }
        }

        .account-options {
          color: var(--blue-500);
          font-weight: 700 !important;
        }
      }

      .ds-title {
        display: inline-flex;
          align-items: center;
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;

          .icon {
              height: 24px;
              width: 24px;
              margin-left: 5px;
              stroke: var(--neutral-500);
          }
      }

      .icon {
        width: 25px;

      }

      @media (min-width: 1440px){
        .icon
        {
          margin-left: 5px;
        }
      }

      .pagination-position {
        width: 100%;
        display: inline-flex;
        justify-content: flex-end;
      }

      label {
        width: 100%;
        font-family: 'Nunito Sans';
        font-weight: 700;
        font-size: 16px;
        color: var(--type-active);
        text-align: left !important;
        color: var(--dark-blue);
        margin-bottom: 4px;
      }

      .content {
        margin: 0 auto;
      }

      .grid-table {
        width: 100%;
        height: min-content;
      }

      tr {
        width: 100%;
        vertical-align: top;
      }

      td {
        padding: 5px;
      }

      .small-card {
        width: 25% !important;
      }

      .large-card {
        width: 50% !important;
      }

      .medium-card {
        width: 25% !important;
      }

      .row {
        padding: 5px;
      }

      .noDataCard {
        display: flex;
        justify-content: center;
        height: 100%;
        border-radius: 8px;
      }

      .dashboard-bottom {
        padding: 5px;

        .dashboard-bottom-container-extern {
          height: auto;
          width: 100%;
          border: 1px solid #D9DFF2;
          border-radius: 8px;

          .dashboard-bottom-container-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 24px 24px 0;

            .dashboard-bottom-container-title {
              font-family: 'Red Hat Display';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 150%;
              color: var(--dark-blue);
            }

            .dx-datepicker {
              caret-color: transparent;

              #chevron {
                cursor: pointer;
                transition: all 500ms;
              }

              .datepicker-chevrondown-icon {
                fill: transparent;
                stroke: var(--blue-500);
                height: 24px;
                width: 24px;
              }

              .chevron-active {
                transform: rotate(-180deg);
              }
            }
          }

          .dashboard-bottom-container-intern {
            display: grid;
            grid-template-columns: 2.2fr 1fr;
            height: auto;
            width: 100%;
            padding: 24px;
            gap: 16px;

            .week-total-values {
              display: grid;
              grid-template-columns: 1fr;
              gap: 16px;
            }
          }
        }
      }
      .cash-flow-wrapper {
        padding: 5px;      
      }
  }
}
</style>
